/* eslint-disable eqeqeq */
/* eslint-disable one-var */
/* eslint-disable no-array-constructor */
/* eslint-disable no-redeclare */
/* eslint-disable no-undef */
import {isNULL} from './validate'

// import QRCode from 'qrcodejs2'

/**
 * 去除字符串首尾两端空格
 * @param {String} str
 * @return {String}
 */
export function trim(str) {
  if (str) {
    return str.replace(/(^\s*)|(\s*$)/g, '')
  } else {
    return ''
  }
}

/**
 * 脱敏
 * @param {String} value 脱敏的对象
 * @return {String}
 */

export function desensitization(value) {
  if (value) {
    var valueNew = ''
    const length = value.length
    // 脱敏：从倒数第五位开始向前四位脱敏
    valueNew = value.split('').map((number, index) => {
      const indexMin = length - 8
      const indexMax = length - 5
      if (index >= indexMin && index <= indexMax) {
        return '*'
      } else {
        return number
      }
    }).join('')
    return valueNew
  } else {
    return ''
  }
}

export function desensitizationIdNumber(value) {
  if (value) {
    const length = value.length
    let valueList = value.split('')
    let val1 = valueList.filter((item, index) => {
      return index < 4
    }).join('')
    let val2 = valueList.filter((item, index) => {
      return index > length - 5
    }).join('')
    return val1 + '****' + val2
  } else {
    return ''
  }
}
/**
 * 通过身份证号计算出生日期
 * @param idCard
 */
export function getBirthday(idCard) {
  var birthday = ''
  if (idCard != null && idCard !== '') {
    if (idCard.length === 15) {
      birthday = '19' + idCard.slice(6, 12)
    } else if (idCard.length === 18) {
      birthday = idCard.slice(6, 14)
    }
    birthday = birthday.replace(/(.{4})(.{2})/, '$1-$2-')
    // 通过正则表达式来指定输出格式为:1990-01-01
  }
  return birthday
}

/**
 * 根据身份证计算性别
 * @param idCard
 */
export function getSex(idCard) {
  var sexStr = 0
  if (idCard.length === 15 || idCard.length === 18) {
    if (parseInt(idCard.slice(-2, -1)) % 2 === 1) {
      sexStr = 1
    } else {
      sexStr = 2
    }
  }
  return sexStr
}

/**
 * 解决ios不支持 - 的日期格式
 * @param date
 * @returns {Date}
 */
export function newDate(date){
  if (date) {
    return new Date(date.replace(/-/g, '/'));
  } else {
    return new Date();
  }
}
//  计算时间差
export function time_dis (direct_time) {
  // direct_time格式为yyyy-mm-dd hh:mm:ss 指定时间
  var now_time = Date.parse(new Date());//当前时间的时间戳
  var end_time = Date.parse(new Date(direct_time));//指定时间的时间戳
  if (end_time<now_time) {
    //  截止时间已过
    return false
  }else {
    //计算相差天数
    var time_dis = end_time - now_time;
    var days=Math.floor(time_dis/(24*3600*1000));
    //计算出小时数
    var leave1=time_dis%(24*3600*1000);//计算天数后剩余的毫秒数
    var hours=Math.floor(leave1/(3600*1000));
    //计算相差分钟数
    var leave2=leave1%(3600*1000);//计算小时数后剩余的毫秒数
    var minutes=Math.floor(leave2/(60*1000));
    //计算相差秒数
    var leave3=leave2%(60*1000);//计算小时数后剩余的毫秒数
    var second = leave3/1000;
    return {days, hours, minutes, second}
  }
}
/**
 * 格式化日期
 * @param date
 * @returns {string}
 */
export function formatterDate(date) {
  if (!isNULL(date)) {
    var day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    var month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' +
      (date.getMonth() + 1)
    return date.getFullYear() + '-' + month + '-' + day
  }
}

/**
 * 格式化年月
 * @param date
 * @returns {string}
 */
export function formatterMonth(date) {
  if (!isNULL(date)) {
    var day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    var month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' +
        (date.getMonth() + 1)
    return date.getFullYear() + '-' + month
  }
}

/**
 * 格式化月
 * @param date
 * @returns {string}
 */
export function formatterOnlyMonth(date) {
  if (!isNULL(date)) {
    var day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    return (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' +
        (date.getMonth() + 1)
  }
}

/**
 * 格式化年
 * @param date
 * @returns {string}
 */
export function formatterYear(date) {
  if (!isNULL(date)) {
    var day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    var month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' +
        (date.getMonth() + 1)
    return date.getFullYear()
  }
}

/**
 * 格式化时间
 * @param date
 * @returns {string}
 */
export function formatterDateTime(date) {
  if (!isNULL(date)) {
    var day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    var month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' +
      (date.getMonth() + 1)
    var hours = (date.getHours() > 9 ? (date.getHours()) : '0' + (date.getHours()))
    var minutes = (date.getMinutes() > 9 ? (date.getMinutes()) : '0' + (date.getMinutes()))
    var seconds = (date.getSeconds() > 9 ? (date.getSeconds()) : '0' + (date.getSeconds()))
    return date.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds
  }
}

/**
 * 格式化不带秒的时间
 * @param te
 * @returns {string}
 */
export function formatterDateTimeWithOutSec(te) {
  if (te != null) {
    var date;
    if (typeof te === "string" && te.indexOf("-") >= 0){
      date = newDate(te);
    } else {
      date = new Date(te)
    }
    var day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate()
    var month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' +
      (date.getMonth() + 1)
    var hours = (date.getHours() > 9 ? (date.getHours()) : '0' + (date.getHours()))
    var minutes = (date.getMinutes() > 9 ? (date.getMinutes()) : '0' + (date.getMinutes()))
    return date.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + minutes
  } else {
    return '--'
  }
}

/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {cb}
 */
function getBlob(url, cb) {
  var xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'blob'
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response)
    }
  }
  xhr.send()
}

/**
 * 二进制文件下载
 * @param responseData 二进制内容
 * @fileName 导出的文件名称
 */
export function dowload(responseData, fileName) {
  let blob = new Blob([responseData])
  let elink = document.createElement('a')
  if (fileName.indexOf('/')) {
    fileName = fileName.substr(fileName.lastIndexOf('/') + 1)
  }
  elink.download = fileName
  elink.style.display = 'none'
  elink.target = '_blank'
  elink.href = URL.createObjectURL(blob)
  document.body.appendChild(elink)
  elink.click()
  URL.revokeObjectURL(elink.href) // 释放URL对象
  document.body.removeChild(elink)
}

/**
 * url文件下载
 * @param responseData 二进制内容
 * @fileName 导出的文件名称
 */
export function dowloadByUrl(url, fileName) {
  getBlob(url, function (blob) {
    // let blob1 = new Blob([blob])
    let elink = document.createElement('a')
    if (fileName.indexOf('/')) {
      fileName = fileName.substr(fileName.lastIndexOf('/') + 1)
    }
    elink.download = fileName
    elink.style.display = 'none'
    elink.target = '_blank'
    elink.href = URL.createObjectURL(blob)
    document.body.appendChild(elink)
    elink.click()
    URL.revokeObjectURL(elink.href) // 释放URL对象
    document.body.removeChild(elink)
  })
}

/**
 * 将对象的组织机构属性转为数组
 * @param obj
 * @returns {[]}
 */
export function getOrgArray(obj) {
  let orgArray = []
  if (obj.province && obj.province > 0) {
    orgArray.push(obj.province)
  }
  if (obj.city && obj.city > 0) {
    orgArray.push(obj.city)
  }
  if (obj.county && obj.county > 0) {
    orgArray.push(obj.county)
  }
  if (obj.street && obj.street > 0) {
    orgArray.push(obj.street)
  }
  if (obj.community && obj.community > 0) {
    orgArray.push(obj.community)
  }
  if (obj.subarea && obj.subarea > 0) {
    orgArray.push(obj.subarea)
  }
  return orgArray
}

/**
 * 动态生成二维码
 * @param elRef 生成二维码div的ref值
 * @param elId 生成二维码div的id
 * @param content 生成二维码的内容
 * @param size 二维码的尺寸大小
 */
export function createQrcode(elRef, elId, content, size) {
  // 每次先清空，以免生成多个
  document.getElementById(elId).innerHTML = ''
  // eslint-disable-next-line no-unused-vars,no-new
  new QRCode(elRef, {
    text: content, // 需要转换为二维码的内容
    width: size === undefined ? 150 : size,
    height: size === undefined ? 150 : size,
    colorDark: '#000000',
    colorLight: '#ffffff',
    correctLevel: QRCode.CorrectLevel.H
  })
}

/**
 * 身份证校验
 * @param idcode
 * @returns {boolean}
 * @constructor
 */
export function IdentityCodeValid(idcode) {
  // 加权因子
  var weightFactor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
  // 校验码
  var checkCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']

  var code = idcode + ''
  var last = idcode[17]// 最后一位

  var seventeen = code.substring(0, 17)

  // ISO 7064:1983.MOD 11-2
  // 判断最后一位校验码是否正确
  var arr = seventeen.split('')
  var len = arr.length
  var num = 0
  for (var i = 0; i < len; i++) {
    num = num + arr[i] * weightFactor[i]
  }
  // 获取余数
  var resisue = num % 11
  var lastNo = checkCode[resisue]

  // 格式的正则
  // 正则思路
  /*
  第一位不可能是0
  第二位到第六位可以是0-9
  第七位到第十位是年份，所以七八位为19或者20
  十一位和十二位是月份，这两位是01-12之间的数值
  十三位和十四位是日期，是从01-31之间的数值
  十五，十六，十七都是数字0-9
  十八位可能是数字0-9，也可能是X
  */
  var idcardPatter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0-9][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/

  // 判断格式是否正确
  var format = idcardPatter.test(idcode)

  // 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
  return !!(last === lastNo && format)
}

/**
 * 是否包含中文
 * @param str
 * @returns {boolean}
 */
export function containChinese(str) {
  if (/.*[\u4e00-\u9fa5]+.*$/.test(str)) {
    return true
  }
  return false
}

/**
 * 格式化时间
 * @param str
 * @returns {boolean}
 */
export function DateFormat(dateTime) {
  var dateParse = Date.parse(new Date(dateTime))
  var time = new Date(dateParse)
  var y = String(time.getFullYear())
  var m = String(time.getMonth() + 1)
  var d = String(time.getDate())
  var h = String(time.getHours())
  var u = String(time.getMinutes())
  var s = String(time.getSeconds())
  if (m.length < 2) m = '0' + m
  if (d.length < 2) d = '0' + d
  if (h.length < 2) h = '0' + h
  if (u.length < 2) u = '0' + u
  if (s.length < 2) s = '0' + s
  return y + '-' + m + '-' + d + ' ' + h + ':' + u + ':' + s
}
/**
 * 单元格样式设置
 * @param row 行
 * @param column 列
 * @param rowIndex 行序
 * @param columnIndex 列序
 * @returns {string}
 */
export function getTableCellStyle(row, column, rowIndex, columnIndex) {
  if (column.label === '证件号码') {
    // 身份证验证，身份证中不包含中文，同时只有证件类型为身份证的需要验证
    if (containChinese(row.idNumber) === false && row.idNumberType === 1) {
      if (IdentityCodeValid(row.idNumber) === false) {
        return 'color:red;'
      }
    }
  } else if (column.label === '年龄') {
    // 年龄验证
    if (row.age == null) {
      return 'color:red;'
    } else {
      if (row.age < 0 || row.age > 120) {
        return 'color:red;'
      } else if (row.age >= 100 && row.age <= 120) {
        return 'color:green;'
      }
    }
  } else if (column.label === '手机号') {
    // 手机号码验证
    // 8位是固定电话，12位带区号固话，11位手机号，20位，8位固话+11位手机号+1位分隔符
    // 23位2个手机号+1位分隔符 24位12位固话+11位手机+1位分隔符
    if (row.mobile) {
      if (row.mobile.length !== 8 && row.mobile.length !== 12 && row.mobile.length !== 11 &&
        row.mobile.length !== 20 && row.mobile.length !== 23 && row.mobile.length !== 24) {
        if (row.mobile.length === 0) {
          return 'color:red;'
        } else {
          return 'color:red;'
        }
      }
    } else {
      return 'color:red;'
    }
  }
}
/**
 *数据不为空校验器
 * @param dataForm 需要校验的数据的集合
 * @param calibrators 校验标准[{target:"目标",name:"目标中文",hint："提示，可不传"}]
 * @param success 反馈的回调函数 function([{target:"目标",value:"反馈内容"}])
 * @return true-通过 false-存在空值,不通过
 **/
export function dataCheck(dataForm, calibrators, success) {
  let feedbacks = []
  for (let i in calibrators) {
    let calibrator = calibrators[i]
    let value;
    if (calibrator.hint) {
      value = calibrator.hint
    } else {
      value = calibrator.name + "不能为空"
    }
    for (let target in dataForm) {
      if (target == calibrator.target) {
        let t = dataForm[target]
        if (objIsNULL(t)) {
          feedbacks.push({
            target: target,
            value: value
          })
        } else if (t) {
          t = t + ""
          t = t.replace(/^\s*|\s*$/g, '');
          if (objIsNULL(t)) {
            feedbacks.push({
              target: target,
              value: value
            })
          }
        }
      }
    }
  }
  isFunction(success);
  if (feedbacks.length > 0) {
    return false;
  } else {
    return true;
  }
}
// 判空
function objIsNULL(obj) {
  if (obj == null || obj === "" || obj == "null" || typeof (obj) == "undefined") {
    return true;
  }
  return false;
}
function isFunction(obj) {
  return typeof obj === 'function';
}


export function mobile(val) {

  if (val && val.length == 11) {
    return val.substring(0, 3) + "****" + val.substring(7, 11);
  } else if (val && val.length > 0) {
    return val;
  } else {
    return '未知';
  }
}

export  function idNumber(val) {
  if (val) {
    if (val.length == 18) {
      return val.substring(0, 6) + "********" + val.substring(12, 18);
    } else {
      var a = (val.length) / 2
      return val.substring(0, a) + "*****"
    }
  }
}

var city = {
  11: "北京",
  12: "天津",
  13: "河北",
  14: "山西",
  15: "内蒙古",
  21: "辽宁",
  22: "吉林",
  23: "黑龙江 ",
  31: "上海",
  32: "江苏",
  33: "浙江",
  34: "安徽",
  35: "福建",
  36: "江西",
  37: "山东",
  41: "河南",
  42: "湖北 ",
  43: "湖南",
  44: "广东",
  45: "广西",
  46: "海南",
  50: "重庆",
  51: "四川",
  52: "贵州",
  53: "云南",
  54: "西藏 ",
  61: "陕西",
  62: "甘肃",
  63: "青海",
  64: "宁夏",
  65: "新疆",
  71: "台湾",
  81: "香港",
  82: "澳门",
  91: "国外 "
};
//身份证号合法性验证
//支持15位和18位身份证号
//支持地址编码、出生日期、校验位验证
export function identityCodeValid(code) {

  var tip = "";
  var pass = true;

  if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
    tip = "身份证号格式错误";
    pass = false;
  } else if (!city[code.substr(0, 2)]) {
    tip = "身份证号格式错误";
    pass = false;
  } else {
    //18位身份证需要验证最后一位校验位
    if (code.length == 18) {
      code = code.split('');
      //∑(ai×Wi)(mod 11)
      //加权因子
      var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
      //校验位
      var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
      var sum = 0;
      var ai = 0;
      var wi = 0;
      for (var i = 0; i < 17; i++) {
        ai = code[i];
        wi = factor[i];
        sum += ai * wi;
      }
      var last = parity[sum % 11];

      if(code[17]=='x'){
        code[17]='X'
      }
      if (parity[sum % 11] != code[17]) {
        tip = "身份证号格式错误";
        pass = false;
      }
    }
  }
  // if(!pass) alert(tip);
  if(pass == true){
    return pass;
  }else{
    return tip;
  }
}

/**
 * 根据身份证号获取出生日期
 * @param {*} idCard
 */
export function getBirthdayFromIdCard(idCard) {
  var birthday = "";
  if(idCard != null && idCard != ""){
    if(idCard.length == 15){
      birthday = "19"+idCard.substr(6,6);
    } else if(idCard.length == 18){
      birthday = idCard.substr(6,8);
    }
    birthday = birthday.replace(/(.{4})(.{2})/,"$1-$2-");
  }
  return birthday;
}

/**
 * 根据身份证获取性别
 * @param {*} idCard
 */
export function getSexFromIdCard(idCard){
  var last = idCard[idCard.length - 2];
  if(last % 2 != 0){
    return 1;
  }else{
    return 2;
  }
}

/**
 * 根据身份证获取籍贯
 * @param {*} idCard
 */
export function getNativePlaceByIdCard(idCard){
  var area =city
  var provinceName = "";
  var provinceNo = idCard.substr(0, 2);
  if (area[parseInt(provinceNo)] != null) {
    provinceName = area[parseInt(provinceNo)];
  }
  return provinceName;
}

/**
 * 是否专有钉浏览器
 * @returns {boolean}
 */
export function isTaurusApp() {
  let ua = navigator.userAgent.toLowerCase();

  if (ua.match(/TaurusApp/i) == 'taurusapp'){
    return true
  } else {
    return false
  }
}

export function dataURItoBlob (dataURI, type) {
  var binary = atob(dataURI.split(',')[1])
  var array = []
  for (var i = 0; i < binary.length; i++) {
    array.push(binary.charCodeAt(i))
  }
  return new Blob([new Uint8Array(array)], { type: type })
}

/**
 * 图片压缩
 * @param file     需要压缩的文件
 * @param fileSize 压缩大小，默认1M
 * @returns {Promise<unknown>}
 */
export function beforeAvatarUpload (files, fileSize) {
  return new Promise((resolve) => {
    if (!fileSize) {
      fileSize = 1024 * 1024;
    }

    let fileArray = []
    for (let i = 0; i < files.length; i++) {
      if (files[i].type.split('/')[0] === 'image' && files[i].size > fileSize) {
        let reader = new FileReader()
        let image = new Image()
        image.onload = (imageEvent) => {
          let blobData = null
          let scale = 0.8
          if (files[i].size > 10 * 1024 * 1024) {
            scale = 0.3
          } else if (files[i].size > 5 * 1024 * 1024) {
            scale = 0.5
          }
          while (true) {
            let canvas = document.createElement('canvas')
            let context = canvas.getContext('2d')
            let width = image.width * scale // 宽缩一半
            let height = image.height * scale // 高缩一半
            canvas.width = width
            canvas.height = height
            context.clearRect(0, 0, width, height)
            context.drawImage(image, 0, 0, width, height)
            let dataUrl = canvas.toDataURL(files[i].type)
            blobData = dataURItoBlob(dataUrl, files[i].type)
            scale = scale - 0.1
            if (blobData.size <= fileSize || scale < 0.1) {
              break
            }
          }
          let newFile = new window.File([blobData], files[i].name, {type: files[i].type})
          fileArray.push(newFile)
          if (fileArray.length === files.length) {
            resolve(fileArray)
          }
        }
        reader.onload = (e) => {
          image.src = e.target.result
        }
        reader.readAsDataURL(files[i])

      } else {
        fileArray.push(files[i])
        if (fileArray.length === files.length) {
          resolve(fileArray)
        }
      }
    }
  })
}

/**
 * 文件上传前的处理
 * @param file
 * @returns {[]}
 */
export function beforeFileUpload (file) {
  if (!Array.isArray(file)) {
    file = [file]
  }

  return beforeAvatarUpload(file)
}
