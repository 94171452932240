import Vue from 'vue'
import router from '@/router'
import store from '@/store'

/**
 * 获取uuid
 */
export function getUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

/**
 * 是否有权限
 * @param {*} key
 */
export function isAuth (key) {
  return JSON.parse(sessionStorage.getItem('permissions') || '[]').indexOf(key) !== -1 || false
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  // Vue.$cookie.delete('token')
  store.commit('resetStore')
  router.options.isAddDynamicMenuRoutes = false
}

export function getImageStream(path, baseurl) {
  if (!path) {
    path = "files/wx/images/content/headImg.png"
  }

  if(!baseurl){
    baseurl = Vue.$baseURL|| process.env.VUE_APP_BASE_URL
  }
  let imageStream = baseurl + "/wxapp/showImage?url=" + path.replace(/\\/g, "/");
  return imageStream;
}

export function formatLabel (resLabels, isOld) {
  var labels = [];
  if (isOld) {
    for (var i = 0; i < resLabels.length; i++) {
      //是否存在第二级
      var exists2 = false;
      if (resLabels[i].parentId == 0) {
        for (var j = 0; j < resLabels.length; j++) {
          if (resLabels[j].parentId == resLabels[i].id) {
            exists2 = true;
            //是否存在第三级标签
            var exists3 = false;
            for (var k = 0; k < resLabels.length; k++) {
              if (resLabels[k].parentId == resLabels[j].id) {
                exists3 = true;
                labels.push({
                  value: resLabels[k].id,
                  label: resLabels[k].name,
                  name: resLabels[i].name + "：" + resLabels[j].name + "(" + resLabels[k].name + ")",
                  ids: resLabels[i].id + "," + resLabels[j].id + "," + resLabels[k].id,
                  rightShow: resLabels[k].rightShow,
                  rightUpdate: resLabels[k].rightUpdate
                })
              }
            }
            if (!exists3) {
              //不存在第三级，则加入第二级
              labels.push({
                value: resLabels[j].id,
                label: resLabels[j].name,
                name: resLabels[i].name + "：" + resLabels[j].name,
                ids: resLabels[i].id + "," + resLabels[j].id,
                rightShow: resLabels[j].rightShow,
                rightUpdate: resLabels[j].rightUpdate
              })
            }
          }
        }
        if (!exists2) {
          //不存在第二级，则加入第一级
          labels.push({
            value: resLabels[i].id,
            label: resLabels[i].name,
            name: resLabels[i].name,
            ids: resLabels[i].id,
            rightShow: resLabels[i].rightShow,
            rightUpdate: resLabels[i].rightUpdate
          })
        }
      }
    }
  } else {
    let ids = resLabels.value
    let options = resLabels.options;
    if (ids.length == 1) {
      let option = options[0]
      //只有第一级
      labels.push({
        value: option.value,
        label: option.label,
        name: option.label,
        ids: option.value,
        rightShow: true,
        rightUpdate: true
      })
    } else if (ids.length == 2) {
      let option1 = options[0]
      let option2 = options[1]
      //不存在第三级，则加入第二级
      labels.push({
        value: option2.value,
        label: option2.label,
        name: option1.label + "：" + option2.label,
        ids: ids.join(","),
        rightShow: true,
        rightUpdate: true
      })
    } else if (ids.length == 3) {
      let option1 = options[0]
      let option2 = options[1]
      let option3 = options[2]
      //不存在第三级，则加入第二级
      labels.push({
        value: option3.value,
        label: option3.label,
        name: option1.label + "：" + option2.label + "(" + option3.label + ")",
        ids: ids.join(","),
        rightShow: true,
        rightUpdate: true
      })
    }
  }
  return labels;
}
