import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import '@/assets/scss/index.scss'
import Vant from 'vant';
import 'vant/lib/index.css';
import Watermark from '@/utils/watermark'
import httpRequest from '@/utils/httpRequest' // api: https://github.com/axios/axios
import httpRequestBack from '@/utils/httpRequest-back'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import FormMaking from '@/formMaking/src/index.js'
import 'form-making/dist/FormMaking.css'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

Vue.use(ElementUI)
Vue.use(FormMaking)
Vue.prototype.$watermark = Watermark
Vue.prototype.$orgId = ''
Vue.prototype.$url = ''
// Vue.prototype.$userName = '管理员'
// Vue.prototype.$orgName = '未知社区'
Vue.prototype.$globalData = {
  limit:10,
  userInfo: {
    accurateNum: "",
    communityName: "",
    domain: null,
    familySum: "",
    fullName: null,
    headImg: null,
    houseId: null,
    idNumber: null,
    keyPlaceSum: 0,
    mobile: "",
    orgId: "",
    peopleSum: 0,
    placeSum: 0,
    subarea: null,
    subareaName: null,
    street: null,
    streetName: null,
    topOrgId: null,
    topUserId: null,
    userId: "",
    userName: "",
    userOrgName: "",
    userPostName: "无",
  },
  ddUser:{
    accountId:'',
    nickNameCn:''
  },
  appletOrgId: '',
  domain: '',
  moduleId: '',
  result: '',
  fileSize: 1024 * 100 * (1024),//上传文件的限制大小单位b
  token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMDQwMjQ3IiwiaWF0IjoxNjM1NzM3MjIyLCJleHAiOjE2MzYzNDIwMjJ9.dkDL55xoAC73rJlkusHI24ha7BpiLB2ON4qA12b5kPzFJeLNWKHtHChU5OsNpvmgy2enHbddKdFNhrkmz0iq7g',
  topToken: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzUxMiJ9.eyJzdWIiOiIxMDQwMjQ3IiwiaWF0IjoxNjM1NzM3MjIyLCJleHAiOjE2MzYzNDIwMjJ9.dkDL55xoAC73rJlkusHI24ha7BpiLB2ON4qA12b5kPzFJeLNWKHtHChU5OsNpvmgy2enHbddKdFNhrkmz0iq7g',
  backToken: '',
  key:'',
  appid:'wx245d433a2e482e22'
  // appid:'wggg-o5dle41W89Tg8xS6X96yaMB7n'
  // appid:'wxcd8dbbf3f2ba3b14'
}

//全局注册自定义指令，用于判断当前图片是否能够加载成功，可以加载成功则赋值为img的src属性，否则使用默认图片
Vue.directive('real-img', async function (el, binding) {//指令名称为：real-img
  let imgURL = binding.value;//获取图片地址
  if (imgURL) {
    let exist = await imageIsExist(imgURL);
    if (exist) {
      el.setAttribute('src', imgURL);
    }
  }
})

/**
 * 检测图片是否存在
 * @param url
 */
let imageIsExist = function(url) {
  return new Promise((resolve) => {
    var img = new Image();
    img.onload = function () {
      if (this.complete == true){
        resolve(true);
        img = null;
      }
    }
    img.onerror = function () {
      resolve(false);
      img = null;
    }
    img.src = url;
  })
}

Vue.prototype.$http = httpRequest // ajax请求方法
Vue.prototype.$httpBack = httpRequestBack
Vue.use(Vant);

Vue.config.productionTip = false
console.log(process.env);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
