import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    noticeId: null,
    activityId:null,
    patrolId:null,
    guideId:null,
    repairData: {},
    problemData:null,
    problemHandling:{},
    houseId:0,
    migrantNum: 0, // 流动人口
    deviceId:0,
    visitId: 1,
    problemVisitIds: ''
  },
  mutations: {
    setNoticeId(state,id) {
      state.noticeId = id || null
    },
    setActivityId(state,id){
      state.activityId = id || null
    },
    setPatrolId(state,id){
      state.patrolId = id || null
    },
    setGuideId(state,id){
      state.guideId = id || null
    },
    // 问题跳转详情
    setProblemData(state,data) {
      state.problemData = data || {}
    },
    // 问题id
    setProblemIds(state,ids) {
      state.problemVisitIds += ids + ','
    },
    setProblemHandling(state,data) {
      state.problemHandling = data || {}
    },
    setHouseId(state,id) {
      state.houseId = id || 0
    },
    // 设备
    setDeviceId(state,id){
      state.deviceId = id || 0
    },
    setVisitId(state,id) {
      state.visitId = id || 1
    },
    // 报修跳转详情
    setRepairData(state,data) {
      state.repairData = data || {}
    },
    setMigrantNum(state,num) {
      state.migrantNum = num || 0
    }
  },
  actions: {
  },
  modules: {
  }
})
