import Vue from "vue";
import router from "../router"
import {Toast} from "vant";

/**
 *获取字典树型结构,即可以查询非顶级字典
 * @param data -{code:"code值",orgId:"字典管理者orgId值,默认为0",showDisabled:"true/false是否显示禁用的默认false"}
 * @param success -回调
 */
 export  function getDictTree(data, success) {
  if (data.showDisabled==undefined) {
    data["showDisabled"]=false
  }
  if(data.orgId==undefined){
      data["orgId"]=Vue.prototype.$globalData.userInfo.orgId
  }
   Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl('/wxapp/sys/dict/listDictByCode'),
    method: 'post',
    params: Vue.prototype.$http.adornParams({...data})
  }).then(({data})=> {
    if (data.code == 0) {
        for (let i in data.dicts) {
            let dict = data.dicts[i];
            dict["label"] = dict.label;
            dict["value"] = dict.value + "";
        }
         success(data.dicts);
    } else {
      success(data)
    }
  },(err)=> {
    success(err)
  })
}
/**
 *获取社区下小区列表
 * @param data -{communityId:"字典管理者orgId值,默认为0"}
 * @param success -回调
 */
export function getbelongSubArea(communityId, success) {
    //debugger
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl('/wxapp/sys/org/subAreaList'),
    method: 'post',
    params: Vue.prototype.$http.adornParams({communityId},false)
  }).then(({data})=> {
      //debugger
    if (data.code == 0) {
      for (let i in data.subAreaList) {
        let subArea = data.subAreaList[i];
        subArea["label"] = subArea.name;
        subArea["value"] = subArea.id + "";
      }
        success(data.subAreaList);
    }else {
        success(data)
    }
  },(err)=> {success(err)})
}
/**
 *获取所属社区
 * @param data -{orgId:"字典管理者orgId值,默认为0"}
 * @param success -回调
 */
export function getBelongCommunity(orgId, depth, success) {
    Vue.prototype.$http({
        url: Vue.prototype.$http.adornUrl('/wxapp/building/house/liveWhichTreeByDepth'),
        method: 'get',
        params: Vue.prototype.$http.adornParams({id: orgId, depth: depth},false)
    }).then(({data})=> {
        if (data.code == 0) {
            success(data.liveWhichTree);
        } else {
            success(data)
        }
    }, err => success(err))
}
/**
 *获取社区所属网格列表
 * @param data -{orgId:"字典管理者orgId值,默认为0"}
 * @param success -回调
 */
export function getbelongGrid(data,success) {
    if(data==null){
        data={orgId:Vue.prototype.$orgId}
    }
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl('/wxapp/grid/info/listByUser'),
    method: 'post',
    params: Vue.prototype.$http.adornParams({community:data.orgId},false)
  }).then(({data})=> {
    if (data.code == 0) {
      for (let i in data.grids) {
        let grid = data.grids[i];
        grid["label"] = grid.name;
        grid["value"] = grid.id + "";
      }
      success(data.grids);
    }else {
      success(data)
    }
  },(err)=> {success(err)})
}

/**
 *获取社区到所属微网格列表
 * @param data -{community:"字典管理者orgId值,默认为0"}
 * @param success -回调
 */
 export function getMiniGrid(data, success) {
  Vue.prototype.$http({
      url: Vue.prototype.$http.adornUrl('/wxapp/grid/info/gridAndMini'),
      method: 'post',
      params: Vue.prototype.$http.adornParams({community:Vue.prototype.$orgId},false)
  }).then(({data})=> {
      if (data.code == 0) {
          success(data.treeVOS);
      }else {
          success(data)
      }
  },(err)=> {success(err)})
}

/**
 *文件上传
 * @param file -图片
 * @param path -文件路径
 * @param fileName -文件名，可不传，不传为uuid
 * @param success -回调
 */
export function upload(file,path,fileName, success) {
    let formdata = new FormData();
    formdata.append("file", file);
    formdata.append("path", path);
    if(fileName!=null){
        formdata.append("fileName", fileName);
    }
    Vue.prototype.$http({
        url: Vue.prototype.$http.adornUrl('/wxapp/file/upload'),
        method: 'post',
        data: formdata
    }).then(({data}) => {
        success(data)
    }, err => success(err))
}


/**
 * 街道子系统登录
 * @param {*} homePageVO
 * @param {*} topToken
 * @param {*} code
 */
export function twoDomainLogin(homePageVO, topToken, code) {
    // alert("twoDomainLogin")
    Vue.prototype.$http({
        url: Vue.prototype.$http.adornUrl(`/wxapp/getTwoDomainLogin`),
        method: 'post',
        data: Vue.prototype.$http.adornData({
            'homePageVO': homePageVO,
            'topToken': topToken
        })
    }).then(({data}) => {
        // alert("twoDomainLogin==="+JSON.stringify(data));
        if (data.code == 0) {

            for (let i in data.homePageVO) {
                let value = data.homePageVO[i]
                if (!value) {
                    value = ""
                }
            }

            // 如果该方法执行成功则接收返回值
            console.log("login success")
            Vue.prototype.$globalData.token = data.token
            Vue.prototype.$globalData.userInfo = data.homePageVO
            Vue.prototype.$orgId = data.homePageVO.orgId

            // alert("/home")
            // alert("11111111111="+Vue.$baseURL)

            // window.location.href=Vue.$baseURL+"/home"
            router.push('/home')
            // alert("/home后")

        } else {
            console.log("login fail")
            // 如果请求失败,则表示没有openId , 需要跳转到登录页面
            Toast.fail(data.msg);
            router.push({path: '/login', query: {code: code}})
            // Vue.prototype.$router.push({path: '/login', query: {code: code}})
        }
    })
}

/**
 * 获取用户，社区有权限的标签列表
 * @param {} success
 */
export function listComRightLabel(success) {
    Vue.prototype.$http({
        url: Vue.prototype.$http.adornUrl(`/wxapp/user/label/treeList`),
        method: 'get',
        params: Vue.prototype.$http.adornParams({
            orgId: Vue.prototype.$orgId
        })
    }).then(({data}) => {
        if (data.code == 0) {
            data.labels.map((e)=>{
                let parameters = e.parameters
                if(parameters){
                    e["disabled"]=!parameters.rightUpdate
                }
            })
            success(data.labels);
        }else{
            Toast.fail("获取标签列表失败");
            // app.showFailToast("获取标签列表失败", 1500)
        }
    })
}

/**
 *获取虚拟字典list，
 *@param code -
 *  catType-座驾类型，useType-房屋属性，relationships-与户主关系，
 *  solveStatus-受理状态，placeHouseType-场所房屋类型，
 *  registryType-户籍性质，petitionLevel-上访等级
 * @param success -回调
 */
export function getVirtualDict(code, success) {

    Vue.prototype.$http({
        url: Vue.prototype.$http.adornUrl(`/wxapp/sys/dict/virtual`),
        method: 'get',
        params: Vue.prototype.$http.adornParams({
            code: code
        })
    }).then(({data}) => {
        if (data.code == 0) {
            data.virtualDicts.map((v)=>v.value=v.value+"")
            success(data.virtualDicts);
        }else{
            Toast.fail("获取"+code+"失败");
        }
    })
}
export function  uploadImg (file, path,success) {
  let formdata = new FormData();
  formdata.append("file", file);
  formdata.append("path", path);
  Vue.prototype.$http({
    url: Vue.prototype.$http.adornUrl('/wxapp/file/upload'),
    method: 'post',
    data: formdata
  }).then(({data})=> {
    success(data)
  },err=>success(err))
}
