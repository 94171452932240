// 应用名称：街道果果
//
// 应用标识：wggg
//
// App Key：wggg-o5dle41W89Tg8xS6X96yaMB7n
//
// App Secret：4c07x5w10Q5mLbndKo0Q4ZvTRXk2ccBi58J5O850
//
// 浙政钉租户id：196729
// 浙政钉域名 ：openplatform-pro.ding.zj.gov.cn


import {isTaurusApp} from "@/utils/utils";

(function (w, d, s, q, i) {
    w[q] = w[q] || [];
    var f = d.getElementsByTagName(s)[0], j = d.createElement(s);
    j.async = true;
    j.id = 'beacon-aplus';
    j.src = 'https://alidt.alicdn.com/alilog/mlog/aplus_cloud.js';
    f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'aplus_queue');

export function setSDK(street) {
    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['aplus-rhost-v', 'alog.zjzwfw.gov.cn']
    });
    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['aplus-rhost-g', 'alog.zjzwfw.gov.cn']
    });

    var u = navigator.userAgent
    var isAndroid = u.indexOf('Android') > -1
    var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)

    if (street == 12573) {
        aplus_queue.push({
            action: 'aplus.setMetaInfo',
            arguments: ['appId', isAndroid ? '28302650' : isIOS ? '28328447' : '47130293']
        });
    }
}

export function waiting() {
    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['aplus-waiting', 'MAN']
    });//
}

export function sendPV(street, pageName, pagePath) {
    // 单页应用路由切换后 或 在异步获取到pv日志所需的参数后再执行sendPV：
    let sapp_id, sapp_name;
    if (street == 12573) {
        sapp_id = '15800'
        sapp_name = 'wggg'
    }
    aplus_queue.push({
        'action': 'aplus.sendPV',
        'arguments': [{
            is_auto: false
        }, {
            // 当前你的应用信息，此两行请勿修改
            sapp_id: sapp_id,
            sapp_name: sapp_name,
            // 自定义PV参数key-value键值对（只能是这种平铺的json，不能做多层嵌套），如：
            page_id: pageName,
            page_url: pagePath,
            page_name: pageName
        }]
    })
}

// 用户信息埋点
// 如采集用户信息是异步行为需要先执行这个BLOCK埋点
export function blockUser() {
    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['_hold', 'BLOCK']
    });
}

export function setUser(userNick, userId) {
    // 设置会员昵称
    aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["_user_nick", userNick]
    });
    // 设置会员ID
    aplus_queue.push({
        action: "aplus.setMetaInfo",
        arguments: ["_user_id", userId]
    });
}

// 如采集用户信息是异步行为，需要先设置完用户信息后再执行这个START埋点
// 此时被block住的日志会携带上用户信息逐条发出
export function startUser() {
    aplus_queue.push({
        action: 'aplus.setMetaInfo',
        arguments: ['_hold', 'START']
    });
}

// 浙政钉日志埋点
export function aplusLog(street, accountId, nickName, pageName, pagePath) {
    //浙政钉手机端
    if (isTaurusApp()) {
        // 基础埋点
        setSDK()
        // 用户埋点
        setUser(accountId, nickName)
        // pv埋点
        sendPV(street, pageName, pagePath)
    }
}
