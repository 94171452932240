// 公钥key
const publicKey = 'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAoxXxPZ9nnzyNQKUKhVox\n' +
  'jgknIk/Vh02f138LoPXfBaz6BDGK4U0VdHLZ/V6rZ9TxFNXMsamLyAJTojk+lECs\n' +
  'AnpLU6ZRPJNHYtKUCpNKtg4dBa9HzGkB9PqBe4bDRT7dsnHmxkAFJoEJecuJiVRz\n' +
  't8H9CMA0rPBiLs/+hAolFG/zeaC2NjifEiUsO22TCoDSnjygG13ZNvBixG4BKPjs\n' +
  'q0eY3VEl8L2ID4q0kc7L1ksPHLA/BQDq/ZHRrjWIN7B9pz7ag6W5qRwRp3jVPH9p\n' +
  '9Xw8JCuGTjjF3p0AomcALT2A4tAepOwSAwgoDRl2S6FN4w2ooOdS+oK1pMxFJJKw\n' +
  'FQIDAQAB'

// eslint-disable-next-line import/first
import JSEncrypt from 'jsencrypt' // 处理长文本数据时报错 jsencrypt.js Message too long for RSA
// eslint-disable-next-line import/first
import Encrypt from 'encryptlong' // encryptlong是基于jsencrypt扩展的长文本分段加解密功能。

export default {
  /* JSEncrypt加密 */
  rsaPublicData (data) {
    var jsencrypt = new JSEncrypt()
    jsencrypt.setPublicKey(publicKey)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    var result = jsencrypt.encrypt(data)
    return result
  },

  /* 加密 */
  encrypt (data) {
    const PUBLIC_KEY = publicKey
    var encryptor = new Encrypt()
    encryptor.setPublicKey(PUBLIC_KEY)
    // 如果是对象/数组的话，需要先JSON.stringify转换成字符串
    const result = encryptor.encrypt(data)
    return result
  }
}
