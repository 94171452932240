import Vue from 'vue'
import Router from 'vue-router'
import versionTood from '@/utils/versionUpdate'

Vue.use(Router)
// 解决路由跳转报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// 针对 push 方法
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// 针对 replace 方法
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}
const routes = [
  {path: '/', name: 'white', redirect:'/white'},
  {path: '/white', name: 'white', component: () => import('../views/common/white.vue')},
  {path: '/login', name: 'login', component: () => import('../views/common/login.vue')},
  {path: '/normally', name: 'normally', component: () => import('../views/common/normally.vue')},
  {path: '/home', name: 'Home', component: () => import('../views/home/Home.vue')},
  {path: '/work', name: 'work', component: () => import('../views/home/work.vue')},
  {path: '/statistics', name: 'statistics', component: () => import('../views/statistics/statistics.vue')},
  {path: '/all-preview', name: 'all-preview', component: () => import('../views/common/all-preview.vue')},
  {path: '/sso', name: 'sso', component: () => import('../views/common/sso.vue')},
  {path: '/fourSSO', name: 'fourSSO', component: () => import('../views/common/fourSSO.vue')},
  {path: '/manage-remind', name: 'manage-remind', component: () => import('../views/common/manage-remind.vue')},
  {path: '/all-preview', name: 'all-preview', component: () => import('../views/common/all-preview.vue')},
  {path: '/my', name: 'my', component: () => import('../views/my/my.vue') },
  {path: '/my-info', name: 'my-info', component: () => import('../views/my/my-info.vue') },
  {path: '/my-info-change', name: 'my-info-change', component: () => import('../views/my/my-info-change.vue') },
  {path: '/my-qrcode', name: 'my-qrcode', component: () => import('../views/my/my-qrcode.vue') },
  {path: '/pwd-change', name: 'pwd-change', component: () => import('../views/my/pwd-change.vue') },
  {path: '/mobile-change', name: 'mobile-change', component: () => import('../views/my/mobile-change.vue') },
  {path: '/userRes-index', name: 'userResIndex', component: () => import('../views/userRes/index.vue'), meta:{title: '居民管理', keepAlive: true} },
  {path: '/userRes-info', name: 'userResInfo', component: () => import('../views/userRes/info.vue'), meta:{title: '居民信息', keepAlive: false}  },
  {path: '/petition-info', name: 'petitionInfo', component: () => import('../views/userRes/petition-info.vue'), meta:{title: '上访信息'}  },
  {path: '/cause-info', name: 'causeInfo', component: () => import('../views/userRes/cause-info.vue'), meta:{title: '肇事信息'}  },
  {path: '/house', name: 'house', component: () => import('../views/house/house.vue'), meta:{title: '房屋管理', keepAlive: true} },
  {path: '/house-info', name: 'house-info', component: () => import('../views/house/house-info.vue'), meta:{title: '房屋详情', isRefresh: 1, fromRefresh: 0, keepAlive: true} },
  {path: '/house-add', name: 'house-add', component: () => import('../views/house/house-add.vue'), meta:{title: '新增房屋'} },
  {path: '/house-move', name: 'house-move', component: () => import('../views/house/house-move.vue'), meta:{title: '更多'} },

  {path: '/place', name: 'place', component: () => import('../views/place/place.vue'), meta:{title: '场所管理'}  },
  {path: '/place-info', name: 'place-info', component: () => import('../views/place/place-info.vue'), meta:{title: '场所详情'}  },

  {path: '/visit', name: 'visit', component: () => import('../views/visit/visit.vue'), meta: {title: '走访', isDel: 1, keepAlive: false}},
  {path: '/survey', name: 'survey', component: () => import('../views/visit/survey.vue') },
  {path: '/code', name: 'code', component: () => import('../views/visit/code.vue') },
  {path: '/visit-add', name: 'visit-add', component: () => import('../views/visit/visit-add.vue'), meta: {title: '新增走访', isRefresh: 1,keepAlive: true}},
  {path: '/resList', name: 'resList', component: () => import('../views/visit/resList.vue') },
  {path: '/all-preview', name: 'all-preview', component: () => import('../views/common/all-preview.vue')},
  {path: '/notice', name: 'notice', component: () => import('../views/notice/notice.vue'), meta: {title: '公告', isDel: 1, keepAlive: true}},
  {path: '/notice-info',name:'notice-info',component:() => import('../views/notice/notice-info.vue'), meta: {title: '公告详情', isDel: 0, keepAlive: false}},
  {path: '/activity',name:'activity',component:() => import('../views/activity/activity.vue'), meta: {title: '活动', isDel: 1, keepAlive: true}},
  {path: '/activity-info',name: 'activity-info',component:()=>import('../views/activity/activity-info.vue'), meta: {title: '新增活动', isDel: 0, keepAlive: false}},
  {path: '/activity-add',name: 'activity-add',component:()=>import('../views/activity/activity-add.vue'), meta: {title: '活动详情', isDel: 0, keepAlive: false}},

  {path: '/patrol',name: 'patrol',component:()=>import('../views/patrol/patrol.vue'), meta: {title: '巡查'}},
  {path: '/patrol-add',name: 'patrol-add',component:()=>import('../views/patrol/patrol-add.vue'), meta: {title: '巡查详情', isRefresh: 1,keepAlive: true}},
  {path: '/userRes-index',name: 'index',component:()=>import('../views/userRes/index.vue')},
  {path: '/problem', name: 'problem', component: () => import('../views/problem/problem.vue'), meta:{title: '问题'} },
  {path: '/problem-info', name: 'problem-info', component: () => import('../views/problem/problem-info.vue'), meta:{title: '问题详情'} },
  {path: '/problem-add', name: 'problem-add', component: () => import('../views/problem/problem-add.vue'), meta:{title: '新增问题'}},
  {path: '/problem-four', name: 'problem-four', component: () => import('../views/problem/problem-four.vue'), meta:{title: '新增问题'}},
  {path: '/problem-finish', name: 'problem-finish', component: () => import('../views/problem/problem-finish.vue'), meta:{title: '新增问题'}},
  {path: '/problem-verify', name: 'problem-verify', component: () => import('../views/problem/problem-verify.vue'), meta:{title: '新增问题'}},
  {path: '/problem-handling', name: 'problem-handling', component: () => import('../views/problem/problem-handling.vue'), meta:{title: '处理问题'}},
  {path: '/problem-change', name: 'problem-change', component: () => import('../views/problem/problem-change.vue'), meta:{title: '修改问题'}},
  //  数据统计
  {path: '/statistics-user', name: 'statistics-user', component: () => import('../views/statistics/modules/user.vue') ,meta:{title:"居民统计"}},
  {path: '/statistics-place', name: 'statistics-place', component: () => import('../views/statistics/modules/place.vue') ,meta:{title:"场所统计"}},
  {path: '/statistics-problem', name: 'statistics-problem', component: () => import('../views/statistics/modules/problem.vue') ,meta:{title:"解决问题数统计"}},
  {path: '/statistics-device', name: 'statistics-device', component: () => import('../views/statistics/modules/device.vue') ,meta:{title:"设备统计"}},
  {path: '/statistics-house', name: 'statistics-house', component: () => import('../views/statistics/modules/house.vue') ,meta:{title:"房屋统计"}},
  {path: '/statistics-visit', name: 'statistics-visit', component: () => import('../views/statistics/modules/visit.vue') ,meta:{title:"走访统计"}},
  {path: '/statistics-patrol', name: 'statistics-patrol', component: () => import('../views/statistics/modules/patrol.vue') ,meta:{title:"巡查统计"}},
  {path: '/statistics-active', name: 'statistics-active', component: () => import('../views/statistics/modules/active.vue') ,meta:{title:"活动统计"}},
  {path: '/statistics-check', name: 'statistics-check', component: () => import('../views/statistics/modules/inspect.vue') ,meta:{title:"检查统计"}},
  {path: '/statistics-maintain', name: 'statistics-maintain', component: () => import('../views/statistics/modules/maintain.vue') ,meta:{title:"维护更新统计"}},
  {path: '/myApp', name: 'myApp', component: () => import('../views/myApp/index.vue') },
  {path: '/appSSO', name: 'appSSO', component: () => import('../views/myApp/appSSO.vue') },
  {path: '/normally', name: 'normally', component: () => import('../views/common/normally.vue') },
  {path: '/message', name: 'message', component: () => import('../views/message/message.vue') ,meta:{title:"短信群发"}},
  {path: '/partyActiveSign', name: 'result-info', component: () => import('../views/common/partyActiveSign.vue') ,meta:{title:"活动签到"}},
  {path: '/search-result', name: 'search-result', component: () => import('../views/globalSearch/search-result.vue') ,meta:{title:"搜索结果", keepAlive: true}},
  {path: '/result-info', name: 'result-info', component: () => import('../views/globalSearch/result-info.vue') ,meta:{title:"搜索结果"}}
]

const router = new Router({
  mode: 'history',
  routes
})

router.beforeEach((to,from,next)=>{//beforeEach是router的钩子函数，在进入路由前
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion()
  if(to.meta.title){//判断是否有标题
    document.title = to.meta.title
  }
  let quitList = [
    {from: '/login', to: ['/white']},
    {from: '/home', to: ['/login', '/white']},
    {from: '/white', to: ['/', '/my']},
    {from: '/pwd-change', to: ['/login']},
  ]
  let flag = quitList.some(item => from.path === item.from && item.to.indexOf(to.path) !== -1)
  if (dd && flag) {
      dd.closePage()
    }

  if (window.__wxjs_environment === 'miniprogram' && to.path === '/sso' && from.path !== '/') {
    wx.miniProgram.postMessage({data: 'close'})
    WeixinJSBridge.call('closeWindow')
    wx.miniProgram.navigateBack()
  }
  next()
})

export default router

