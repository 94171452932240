<template>
  <div id="app">
    <keep-alive :include="keepAliveList">
      <!-- 如果当前打开页面的路由中 keepAlive: true （开启了缓存时） -->
      <router-view></router-view>
    </keep-alive>
  </div>
</template>
<script>
import {sendPV, aplusLog} from "@/utils/aplus_cloud";

var that
import http from '@/utils/httpRequest'
import {twoDomainLogin} from '@/utils/common'
import { Toast } from 'vant';
import {isTaurusApp} from "@/utils/utils";
export default {
  data(){
    return{
      keepAliveList: []
    }
  },
  created(){
  },
  methods: {

  },
  // 监听,当路由发生变化的时候执行
  watch: {
    $route: {
      handler: function(val, oldVal){
        if (val.meta.keepAlive && this.keepAliveList.indexOf(val.name) == -1) {
          this.keepAliveList.push(val.name)
        }
        if (oldVal.meta.keepAlive) {
          // 控制旧页面是否刷新
          if (oldVal.meta.isRefresh === 1 && val.meta.fromRefresh !== 0) {
            this.keepAliveList = this.keepAliveList.filter(item => {
              return item !== oldVal.name
            })
          }
        }
        if(!oldVal.meta.keepAlive) {
          this.keepAliveList = this.keepAliveList.filter(item => {
            return item !== oldVal.name
          })
        }
        let del = localStorage.getItem('del') ? true : false
        if (del) {
          localStorage.removeItem('del')
          this.keepAliveList = this.keepAliveList.filter(item => {
            return item !== val.name
          })
        }
        console.log(this.keepAliveList)
        //浙政钉流量分析埋点
        if (isTaurusApp() && val.name !== 'login' && val.name !== 'white' && val.name !== 'sso') { //浙政钉手机端
          aplusLog(this.$globalData.street, this.$globalData.ddUser.accountId,
              this.$globalData.ddUser.nickNameCn, val.name, val.path)
        }
      },
      // 深度观察监听
      deep: true
    }
  }
}

</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f5f5f5;
}
ul,li {list-style: none; margin: 0; padding: 0;}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

</style>
